import {
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Badge from '../components/Badge';
import Button from '../components/Button';
import CarSelector from '../components/CarSelector';
import ServiceSelector from '../components/ServiceSelector';
import useAuthentication from '../hooks/useAuthentication';
import styles from '../sass/components/Services.module.scss';
import { ROUTE_BOOK_ADD_ONS, ROUTE_BOOK_LOCATION, ROUTE_BOOK_SCHEDULE } from '../util/constants';
import {
  FIND_POTENTIAL_FRANCHISE,
  GET_USER_BY_ID,
} from '../util/gql';
import {
  setPotentialFranchiseId,
} from '../features/booking/bookingSlice';

export default function Services() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleLogin, loggedIn } = useAuthentication();
  const selectedServices = useAppSelector((state) => (
    state.serviceTypes.services
  ));
  const selectedCar = useAppSelector((state) => state.car.id);
  const customerId = useAppSelector((state) => state.customer.id);
  const franchiseIdSelect = useAppSelector((state) => state.franchise.id);
  const { franchiseId } = useAppSelector((state) => state.auth.currentUser);
  const {
    serviceAddress,
  } = useAppSelector((s) => s.serviceLocation);
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const [loadUserInformation,
    { data: userInfo }] = useLazyQuery(GET_USER_BY_ID);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 1) {
      const userId = customerId || currentUser.id;
      loadUserInformation({ variables: { userId } });
    }
  }, [customerId, currentUser]);

  const [findPotentialFranchise, {
    loading: franchiseLoading,
  }] = useMutation(FIND_POTENTIAL_FRANCHISE);

  const handleBack = () => {
    navigate(ROUTE_BOOK_LOCATION);
  };

  const handleNextClick = async () => {
    try {
      const response = await findPotentialFranchise({
        variables: {
          addressInput: serviceAddress,
          franchiseIdSelect: franchiseIdSelect || franchiseId,
          customerId,
        },
      });
      const potentialFranchiseId = response?.data?.findPotentialFranchise;
      dispatch(setPotentialFranchiseId(potentialFranchiseId));

      if (userInfo?.getUserById?.vendorId === 1) {
        navigate(ROUTE_BOOK_SCHEDULE);
      } else {
        navigate(ROUTE_BOOK_ADD_ONS);
      }
    } catch (error) {
      console.error('Error finding potential franchise', error);
    }
  };

  return (
    <div className={styles.services}>
      <div className={styles.header}>
        <h1 className={styles.headerDesktop}>Select Services</h1>
        <h4 className={styles.headerMobile}>Select Services</h4>
        <Button
          variant="secondary"
          onClick={handleLogin}
        >
          {loggedIn ? 'Log Out' : 'Log In'}
        </Button>
      </div>
      <div className={cx(styles.body, 'container--fluid')}>
        <div className={cx(styles.bodyLeft, 'container__col-xs-4')}>
          <div className={styles.carSelectorBox}>
            <CarSelector />
          </div>
        </div>
        <div className={cx(
          styles.bodyRight,
          'container__col-xs-8',
        )}
        >
          <ServiceSelector type="primary" />
        </div>
      </div>
      <div className={styles.footer}>
        <Badge
          variant="secondary"
          className={styles.countBadge}
        >
          Note: Only 1 service can be selected
        </Badge>
        <Button
          className={styles.editButton}
          onClick={handleBack}
          variant="tertiary"
        >
          Back
        </Button>
        <Button
          className={styles.editButton}
          variant="secondary"
          onClick={handleNextClick}
          inactive={
            selectedServices.length < 1 || selectedCar === null
          }
          loading={franchiseLoading}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
