import { useLazyQuery } from '@apollo/client';
import { PickersDay, pickersDayClasses, PickersDayProps } from '@mui/lab';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import cx from 'classnames';
import {
  add,
  addHours,
  endOfMonth,
  format,
  parseISO,
  startOfToday,
  startOfMonth,
} from 'date-fns';
import {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAppSelector } from '../app/hooks';
import { Appointment } from '../features/serviceLocation/serviceLocationSlice';
import {
  selectPotentialFranchiseId,
} from '../features/booking/bookingSlice';
import styles from '../sass/components/AppointmentDatePicker.module.scss';
import { GET_AVAILABILITY } from '../util/gql';
import Datepicker from './Datepicker';
import Select from './Select';
import TextInput from './TextInput';
import TimeList from './TimeList';
import {
  MINIMUM_HOURS_BEFORE_BOOKING_ALLOWED,
  FRANCHISE_MINIMUM_HOURS_BEFORE_BOOKING_ALLOWED,
} from '../constants/index.js';

interface AppointmentDatePickerProps {
  onSelect: (date: Appointment) => void;
  value?: Appointment | undefined;
  timeNoLongerAvailable: boolean;
  franchiseId?: number | undefined;
}
export interface AppointmentMap {
  [day: string]: Appointment[]
}

export function getTimeValue(appointment: Appointment): string {
  const convertedDate = parseISO(appointment.date);

  return `${format(convertedDate, 'h:mm aaa')}`
    + ` - ${format(add(convertedDate, {
      hours: 1,
    }), 'h:mm aaa')}`;
}

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayHasAvailability: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayHasAvailability',
})<CustomPickerDayProps>(({ dayHasAvailability }) => ({
  ...(dayHasAvailability && {
    backgroundColor: '#f0f5f8',
    color: '#2e5e7e',
    '&:hover, &:focus': {
      backgroundColor: '#f0f5f8',
    },
  }),
  ...(!dayHasAvailability && {
    backgroundColor: '#FFEFED',
    color: '#2e5e7e',
    '&:hover, &:focus': {
      backgroundColor: '#FFEFED',
    },
  }),
})) as React.ComponentType<CustomPickerDayProps>;

export default function AppointmentDatePicker({
  onSelect,
  value,
  timeNoLongerAvailable,
  franchiseId,
}: AppointmentDatePickerProps) {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1080);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);
  const franchiseIdSelect = useAppSelector((state) => state.franchise.id);
  const potentialFranchiseId = useAppSelector(selectPotentialFranchiseId);

  const [
    day,
    setDay,
  ] = useState<Date | null>(value?.date ? parseISO(value.date) : new Date());
  const [
    appointment,
    setAppointment,
  ] = useState<Appointment | null | undefined>(value);

  const postalCode = useAppSelector((state) => state.postalCode.postalCode);
  const [
    getAvailability, {
      data,
      loading,
    },
  ] = useLazyQuery(GET_AVAILABILITY);

  // const now = useMemo<Date>(() => new Date(), []);
  const now = useMemo(() => {
    if (currentUser.roles?.includes('manager')
      || currentUser.roles?.includes('technician')) {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - 30));
    }
    return new Date();
  }, [currentUser.roles]);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    const resize = () => {
      setIsMobile(window.innerWidth <= 1080);
    };

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);
  let minumHours: number;

  if (currentUser.roles?.includes('manager')
    || currentUser.roles?.includes('technician')) {
    minumHours = FRANCHISE_MINIMUM_HOURS_BEFORE_BOOKING_ALLOWED;
  } else {
    minumHours = MINIMUM_HOURS_BEFORE_BOOKING_ALLOWED;
  }

  useEffect(() => {
    getAvailability({
      variables: {
        start: addHours(now, minumHours).toJSON(),
        end: endOfMonth(day || now).toJSON(),
        postalCode: { code: postalCode },
        userTimezone,
        franchiseId: potentialFranchiseId || franchiseIdSelect || franchiseId,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAvailability, now, postalCode]);

  const mappedAvailability: AppointmentMap = useMemo(() => ((data
    && data.getAvailability)
    ? data.getAvailability.reduce((
      map: AppointmentMap,
      current: Appointment,
    ) => {
      const newMap = map;
      const currentDay = format(parseISO(current.date), 'yyyyMMdd');
      if (newMap[currentDay]) {
        newMap[currentDay].push(current);
      } else {
        newMap[currentDay] = [current];
      }
      return newMap;
    }, {})
    : {}), [data]);

  const handleClick = (
    event: React.KeyboardEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMonthChange = (date: Date) => {
    getAvailability({
      variables: {
        start: now >= date
          ? addHours(now, MINIMUM_HOURS_BEFORE_BOOKING_ALLOWED).toJSON()
          : startOfMonth(date).toJSON(),
        end: endOfMonth(date).toJSON(),
        postalCode: { code: postalCode },
        userTimezone,
        franchiseId: potentialFranchiseId || franchiseIdSelect || franchiseId,
      },
    });
    setDay(null);
  };

  const selectAppointment = (appt: Appointment) => {
    setAppointment(appt);
    onSelect(appt);
    handleClose();
  };

  const getIsDayAvailable = (date: Date): boolean => {
    const formattedDay = format(new Date(date), 'yyyyMMdd');
    let hasDate = false;
    if (mappedAvailability) {
      const avail = mappedAvailability;
      hasDate = Object.keys(avail).some((
        dateKey: string,
      ) => dateKey === formattedDay);
    }
    return hasDate;
  };

  const renderDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>,
  ) => {
    const dateIsAvailable = getIsDayAvailable(date);
    if (date < startOfToday()) {
      return (
        <PickersDay
          key={date.toString()}
          day={pickersDayProps.day}
          onDaySelect={pickersDayProps.onDaySelect}
          outsideCurrentMonth={pickersDayProps.outsideCurrentMonth}
        />
      );
    }

    return (
      <CustomPickersDay
        key={date.toString()}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        dayHasAvailability={dateIsAvailable}
        disabled={!dateIsAvailable}
        sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: '#2E5E7E',
            color: '#F2F2F2',
            '&:hover, &:focus': {
              backgroundColor: '#2E5E7E',
            },
          },
          [`&&.${pickersDayClasses.disabled}`]: {
            backgroundColor: '#FFEFED',
            color: '#2e5e7e',
            '&:hover, &:focus': {
              backgroundColor: '#FFEFED',
            },
          },
        }}
      />
    );
  };

  const selectMobileTime = (event: ChangeEvent<HTMLSelectElement>) => {
    const [routeId, date] = event.currentTarget.value.split('_');
    const appt: Appointment = {
      routeId,
      date,
      isBooked: false,
    };
    setAppointment(appt);
    onSelect(appt);
  };

  const selectedDayTimes = mappedAvailability[
    format(day ? new Date(day) : new Date(), 'yyyyMMdd')
  ];
  const isOpen = Boolean(anchorEl);
  const dayValue = appointment
    ? format(parseISO(appointment.date), 'MM/dd/yy')
    : '';
  const timeValue = appointment
    ? getTimeValue(appointment)
    : '';
  const mobileTime = appointment ? `${appointment?.routeId}_`
    + `${appointment?.date}` : '';
  return (
    <div>
      {isMobile && (
        <div className={cx(styles.wrapper, styles.mobile)}>
          <Datepicker
            onMonthChange={handleMonthChange}
            label="Date"
            value={appointment ? parseISO(appointment.date) : day}
            id="dayPicker"
            renderDay={renderDay}
            loading={loading}
            onChange={(event) => {
              setAppointment(null);
              setDay(event || null);
            }}
            mobile
          />
          <Select
            label="Appointment Time"
            id="appointment-time"
            value={loading ? 'loading' : mobileTime}
            placeholder="Select an Appointment Time"
            onChange={(
              event: React.ChangeEvent<HTMLSelectElement>,
            ) => selectMobileTime(event)}
            disabled={!selectedDayTimes}
            loading={loading}
            errorMessage={timeNoLongerAvailable
              ? 'The time selected is no longer available' : ''}
          >
            {!selectedDayTimes && appointment && (
              <option
                key={`${appointment.routeId}-${appointment.date}`}
                disabled
                value={`${appointment.routeId}_${appointment.date}`}
              >
                {getTimeValue(appointment)}
              </option>
            )}
            {selectedDayTimes
              && selectedDayTimes.map((
                time,
              ) => (
                <option
                  key={`${time.routeId}-${time.date}`}
                  onClick={() => setAppointment(time)}
                  value={`${time.routeId}_${time.date}`}
                >
                  {getTimeValue(time)}
                </option>
              ))}
          </Select>
        </div>
      )}
      <button
        onClick={handleClick}
        className={cx(styles.wrapper, styles.desktop)}
        type="button"
      >
        <TextInput
          title="Date"
          onChange={() => undefined}
          value={dayValue}
          className={styles.dateInput}
        />
        <TextInput
          title="Time"
          onChange={() => undefined}
          value={timeValue}
          className={styles.timeInput}
          errorMessage={timeNoLongerAvailable
            ? 'The time selected is no longer available' : ''}
        />
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={styles.dateAndTimeSection}>
            <Datepicker
              popout={false}
              onMonthChange={handleMonthChange}
              label="Date"
              value={day ? new Date(day) : null}
              id="dayPicker"
              renderDay={renderDay}
              loading={loading}
              onChange={(event) => setDay(event || null)}
            />
            {!!day && (
              <TimeList
                onSelectAppointment={selectAppointment}
                times={selectedDayTimes || []}
                selectedTime={appointment}
              />
            )}
          </div>
        </Popover>
      </button>
    </div>
  );
}
