import { TabDictionary } from '../interfaces/TabDictionary';
import InformationSection from './InformationSection';
import WorkOrderAppointments from './WorkOrderAppointments';
import { WorkOrderStatus } from '../interfaces/WorkOrder';

export default function WorkOrdersInformation() {
  const tabs: TabDictionary = {
    requestedWorkOrders: {
      desktopValue: 'Requested Work Orders',
      mobileValue: 'Requested',
      component: <WorkOrderAppointments
        workOrderStatus={WorkOrderStatus.assigned}
        emptyListMsg="No requested work orders"
        isConfirmed={false}
      />,
    },
    upcomingWorkOrders: {
      desktopValue: 'Upcoming Work Orders',
      mobileValue: 'Upcoming',
      component: <WorkOrderAppointments
        workOrderStatus={WorkOrderStatus.assigned}
        emptyListMsg="No upcoming work orders"
        // eslint-disable-next-line react/jsx-boolean-value
        isConfirmed={true}
      />,
    },
    pastWorkOrders: {
      desktopValue: 'Completed Work Orders',
      mobileValue: 'Completed',
      component: <WorkOrderAppointments
        workOrderStatus={WorkOrderStatus.complete}
        emptyListMsg="No completed work orders"
      />,
    },
  };

  return <InformationSection tabs={tabs} initialState="upcomingWorkOrders" />;
}
