/* eslint-disable max-len */
import { useApolloClient, useQuery } from '@apollo/client';
import Close from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import cx from 'classnames';
import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAuthentication from '../hooks/useAuthentication';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import goOilLogo from '../images/gooil-logo.png';
import styles from '../sass/components/NavBar.module.scss';
import {
  ROUTE_ACCOUNT_ROOT,
  ROUTE_AUTH_LOGIN,
  ROUTE_BOOK_LOCATION,
  ROUTE_BOOK_ROOT,
  ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
  ROUTE_ROOT,
} from '../util/constants';
import { GET_FRANCHISES_OF_FRANCHISE_MANAGER } from '../util/gql';
import {
  setFrnchiseId,
  setFranchiseName,
} from '../features/franchise/franchiseSlice';
import { updateFranchiseId } from '../features/auth/authSlice';

export default function NavBar() {
  const currentRoutePath = useLocation().pathname;
  const navigate = useNavigate();
  const { franchiseId } = useAppSelector((state) => state.auth.currentUser);
  const franchiseIdSelect = useAppSelector((state) => state.franchise.id);
  const franchiseNameSelect = useAppSelector((state) => state.franchise.name);
  const [navOpen, setNavOpen] = useState(false);
  const { loggedIn: isLoggedIn, handleLogin } = useAuthentication();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const hasRole = currentUser.roles?.includes('manager')
    || currentUser.roles?.includes('technician');
  const dispatch = useAppDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [franchises, setFranchises] = useState<any[]>([]);
  const [hasFetched, setHasFetched] = useState(false);

  const { loading, error, data } = useQuery(GET_FRANCHISES_OF_FRANCHISE_MANAGER, {
    fetchPolicy: 'network-only',
    skip: hasFetched, // Skip query execution if it has already fetched
  });

  useEffect(() => {
    if (data && !hasFetched) {
      if (data?.getFranchisesOfFranchiseManager) {
        const formattedFranchises = data.getFranchisesOfFranchiseManager.map((franchise: any) => ({
          id: franchise.id,
          name: franchise.name,
        }));
        setFranchises(formattedFranchises);
      }
      setHasFetched(true);
    }
  }, [data, hasFetched]);

  const setFranchise = (id: number, name: string) => {
    dispatch(setFrnchiseId(id));
    dispatch(setFranchiseName(name));
    dispatch(updateFranchiseId(id));
  };

  const handleFranchiseSelection = (id: number, name: string) => {
    setFranchise(id, name);
    navigate(ROUTE_FRANCHISE_MANAGER_WORK_ORDERS);
  };

  useEffect(() => {
    if ((franchiseIdSelect !== franchiseId) && franchises) {
      const currentFranchise = franchises.find(
        (franchise: any) => franchise.id === franchiseId,
      );
      if (currentFranchise) {
        setFranchise(franchiseId, currentFranchise.name);
      }
    }
  }, [franchiseIdSelect, franchiseId, franchises]);

  return (
    <nav className={cx(styles.navBar, {
      [styles.navBarClosed]: !navOpen,
    }, {
      [styles.navBarOpen]: navOpen,
    })}
    >
      <Link
        className={styles.logo}
        to={ROUTE_ROOT}
        onClick={() => setNavOpen(false)}
      >
        <img src={goOilLogo} alt="Go oil logo" className={styles.logoSvg} />
      </Link>
      <div className={cx(styles.navLinks, {
        [styles.navLinksClosed]: !navOpen,
      }, {
        [styles.navLinksOpen]: navOpen,
      })}
      >
        {isLoggedIn && hasRole && franchises && (
          <div
            className={cx(styles.dropdownContainer, {
              [styles.navLinksClosed]: !navOpen,
              [styles.navLinksOpen]: navOpen,
            })}
          >
            <button
              type="button"
              onClick={() => setDropdownOpen((prev) => !prev)}
              className={cx(styles.dropdownTrigger, {
                [styles.linkClosed]: !navOpen,
                [styles.linkOpen]: navOpen,
              })}
            >
              <h4>{franchiseNameSelect || 'Select a Franchise'}</h4>
            </button>
            {dropdownOpen && (
              <ul className={styles.dropdownList}>
                {franchises.map((franchise: any) => (
                  <button
                    key={franchise.id}
                    type="button"
                    className={styles.dropdownItem}
                    onClick={() => {
                      handleFranchiseSelection(franchise.id, franchise.name);
                      setDropdownOpen(false);
                    }}
                  >
                    {franchise.name}
                  </button>
                ))}
              </ul>
            )}
          </div>
        )}

        <Link
          className={cx(styles.link, {
            [styles.linkClosed]: !navOpen,
          }, {
            [styles.linkOpen]: navOpen,
          }, {
            [styles.currentPage]: currentRoutePath.includes(ROUTE_BOOK_ROOT),
          })}
          to={ROUTE_BOOK_LOCATION}
          onClick={() => setNavOpen(false)}
        >
          BOOK APPOINTMENT
        </Link>
        <Link
          className={cx(styles.link, {
            [styles.linkClosed]: !navOpen,
          }, {
            [styles.linkOpen]: navOpen,
          }, {
            [styles.currentPage]: currentRoutePath.includes(ROUTE_ACCOUNT_ROOT),
          })}
          to={isLoggedIn ? ROUTE_ACCOUNT_ROOT : ROUTE_AUTH_LOGIN}
          onClick={() => setNavOpen(false)}
        >
          ACCOUNT
        </Link>
        {isLoggedIn && navOpen && (
          <Link
            className={cx(styles.link, {
              [styles.linkOpen]: navOpen,
            })}
            to={ROUTE_AUTH_LOGIN}
            onClick={handleLogin}
          >
            LOG OUT
          </Link>
        )}
        <button
          type="button"
          className={cx(styles.button, {
            [styles.menuButton]: !navOpen,
          }, {
            [styles.closeButton]: navOpen,
          })}
          onClick={() => setNavOpen(!navOpen)}
        >
          {navOpen ? <Close /> : <Menu />}
        </button>
      </div>
    </nav>
  );
}
