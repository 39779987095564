import { useState } from 'react';
import { CancelWorkOrderProps } from '../interfaces/Components';
import styles from '../sass/components/ErrorModal.module.scss';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';
import Select from './Select';
import CameraAccess from './CameraAccess';
import AwsUpload from './AwsUpload';

export default function CancelWorkOrder({
  cancelReason,
  setCancelReason,
  proofOfCancelLocation,
  setProofOfCancelLocation,
  notes,
  setNotes,
  onClick,
  onClose,
}: CancelWorkOrderProps) {
  const [imageToProcess, setImageToProcess] = useState(null);
  const [imageName, setImageName] = useState<string | null>(null);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCancelReason(event.target.value);
  };

  const handleCaptureImage = async (capturedImage: any) => {
    setImageToProcess(capturedImage);
    setImageName(`cancel_work_order_${Date.now()}.jpg`);
  };

  const onUploadComplete = (success: boolean, url: string) => {
    if (success) {
      setProofOfCancelLocation(url);
    }
    setImageToProcess(null);
    setImageName(null);
  };

  const isCancelReasonEmpty = cancelReason === '';
  const isThirdPartyWithoutProof = cancelReason === 'vehicle_serviced_by_third_party'
  && !proofOfCancelLocation;
  const isConfirmDisabled = isCancelReasonEmpty || isThirdPartyWithoutProof;

  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Cancel Work Order</h4>
        <p>Select a Reason for cancelling this Work Order</p>
        <Select
          label=""
          loading={false}
          id="Reason Select"
          placeholder="Select a Reason"
          value={cancelReason}
          onChange={handleSelectChange}
        >
          <option value="customer_cancelled">
            Customer Cancelled
          </option>
          <option value="work_order_out_of_range">
            Work order out of Range
          </option>
          <option value="vehicle_serviced_by_third_party">
            Vehicle serviced by third party
          </option>
          <option value="other">Other reason</option>
        </Select>

        <br />
        {cancelReason === 'other' && (
          <>
            <p>Add Notes:</p>
            <textarea
              className={styles.poNumber}
              placeholder="Notes"
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              rows={4}
              cols={100}
              style={{ resize: 'both' }}
            />
            <br />
          </>
        )}
        {cancelReason === 'vehicle_serviced_by_third_party' && (
          <>
            {!proofOfCancelLocation ? (
              <>
                <p>Add a Picture to Confirm Cancellation:</p>
                <CameraAccess
                  onCapture={handleCaptureImage}
                  className={styles.addServiceImage}
                />
                {imageToProcess && imageName && (
                  <AwsUpload
                    capturedImage={imageToProcess}
                    filename={imageName}
                    onUploadComplete={onUploadComplete}
                  />
                )}
              </>
            ) : (
              <p>Picture successfully uploaded</p>
            )}
          </>
        )}
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={onClose}
            variant="tertiary"
          >
            Cancel
          </Button>
          <Button
            className={styles.button}
            onClick={onClick}
            variant="primary"
            inactive={isConfirmDisabled}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
