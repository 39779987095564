import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Selection } from '../../components/PreviousSelector';
import { placeResultToAddress } from '../../components/SearchLocationInput';

type PlaceResult = google.maps.places.PlaceResult;

export interface Appointment {
  isBooked?: boolean;
  date: string;
  routeId: string;
}

export interface Address {
  street: string;
  city: string;
  postalCode: string;
  country: string;
  province: string;
  countryCode: string;
  provinceCode: string;
  lat?: number;
  lng?: number;
}

export interface Contact {
  name: string;
  email: string;
  phoneNumber: string;
}

export interface ServiceLocationState {
  serviceAddress?: Address;
  newServiceAddress?: PlaceResult;
  selectedAddress: Selection;
  dateTime?: Appointment;
  contact: Contact;
  selectedContact: Selection;
}

const initialState: ServiceLocationState = {
  contact: {
    name: '',
    email: '',
    phoneNumber: '',
  },
  selectedAddress: 'unset',
  selectedContact: 'unset',
};

export const serviceLocationSlice = createSlice({
  name: 'seviceLocation',
  initialState,
  reducers: {
    setNewServiceAddress: (
      state,
      action: PayloadAction<PlaceResult | undefined>,
    ) => ({
      ...state,
      newServiceAddress: action.payload,
      serviceAddress: placeResultToAddress(action.payload),
      selectedAddress: 'new',
    }),
    setServiceAddress: (
      state,
      action: PayloadAction<[Selection, Address | undefined]>,
    ) => {
      const [selection, address] = action.payload;
      return {
        ...state,
        serviceAddress: address,
        selectedAddress: selection,
      };
    },
    setDateTime: (
      state,
      action: PayloadAction<Appointment>,
    ) => ({
      ...state,
      dateTime: action.payload,
      isBooked: action.payload,
    }),
    setName: (state, action: PayloadAction<string>) => ({
      ...state,
      contact: {
        ...state.contact,
        name: action.payload,
      },
      selectedContact: 'new',
    }),
    setEmail: (state, action: PayloadAction<string>) => ({
      ...state,
      contact: {
        ...state.contact,
        email: action.payload,
      },
      selectedContact: 'new',
    }),
    setPhone: (state, action: PayloadAction<string>) => ({
      ...state,
      contact: {
        ...state.contact,
        phoneNumber: action.payload,
      },
      selectedContact: 'new',
    }),
    setNewContact: (state) => ({
      ...state,
      contact: {
        name: '',
        email: '',
        phoneNumber: '',
      },
      selectedContact: 'new',
    }),
    setContact: (
      state,
      {
        payload: [selectedContact, contact],
      }: PayloadAction<[Selection, Contact]>,
    ) => ({
      ...state,
      selectedContact,
      contact,
    }),
  },
});

export const {
  setServiceAddress,
  setDateTime,
  setName,
  setEmail,
  setPhone,
  setContact,
  setNewContact,
  setNewServiceAddress,
} = serviceLocationSlice.actions;

export default serviceLocationSlice.reducer;
