import { ReactNode } from 'react';
import { InspectionData, InspectionImages } from './UserVehicle';
import {
  ServiceItem, VehicleSubscription, WorkOrderProps,
} from './WorkOrder';
import { SubscriptionType } from './Subscription';
import { Franchise } from './Franchise';

export interface RowProps {
  title: string
  text?: string
  isItalicText?: boolean
  children?: ReactNode
}

export interface CompleteModalProps {
  onClose: () => void
  title: string
  message: string
  open: boolean
  currentReader: string | null
  invoiceId: number | null
}
export interface SuccessModalProps {
  onClose: () => void
  title: string
  message: string
  open: boolean
  invoiceId: number | null
}

export interface ConfirmationModalProps {
  onClose: () => void
  onSubmit?: () => void
  title: string
  message: string
  open: boolean
}

export interface AssignWorkOrderModalProps {
  onClose: () => void
  onSubmit: (
    assignedFranchise: number,
  ) => void
  franchiseId?: number
}
export interface SendPaymentToTerminalProps {
  onClose: () => void
  setErrorModal: () => void
  open: boolean
  invoiceDetails?: {
    id: number
    franchiseId: number
  },
  setSuccessModal: () => void
  setCurrentReader: (reader: string) => void
}

export interface GetCashAppPaymentProps {
  onClose: () => void
  setErrorModal: () => void
  open: boolean
  invoiceDetails?: {
    id: number
    franchiseId: number
  },
  setSuccessModal: () => void
  isPaid: boolean
  setIsPaid: (isPaid: boolean) => void
}

export interface CashAppProps {
  setErrorModal: () => void
  invoiceDetails?: {
    id: number
    franchiseId: number
  },
  cashAppModal: boolean
  paymentLink: string
  isPaid: boolean
  setIsPaid: (isPaid: boolean) => void
}

export interface ListRowProps {
  title: string
  list?: Array<string>
  children?: ReactNode
}

export interface AssignWorkOrderProps {
  onClose: () => void
  onClick: () => void
  franchises: Franchise[]
  franchiseId?: number
  assignedFranchise: number
  setAssignedFranchise: (value: number)=> void
}

export interface CancelWorkOrderProps {
  cancelReason: string,
  setCancelReason: (value: string)=> void
  proofOfCancelLocation?: string,
  notes: string,
  setNotes: (value: string)=> void
  setProofOfCancelLocation: (value: string)=> void
  onClose: () => void
  onClick: () => void
}

export interface VehicleInspectionProps {
  onClose: () => void
  onClick: () => void
  isContinueButtonInactive: boolean
  inspectionData: InspectionData
  setInspectionData: (data: InspectionData) => void
  checkedFrontLeft: boolean
  setCheckedFrontLeft: (value: boolean)=> void
  checkedFrontRight: boolean
  setCheckedFrontRight: (value: boolean)=> void
  checkedRearLeft: boolean
  setCheckedRearLeft: (value: boolean)=> void
  checkedRearRight: boolean
  setCheckedRearRight: (value: boolean)=> void
  tinLocationFrontLeft: string
  setTinLocationFrontLeft: (value: string)=> void
  tinLocationFrontRight: string
  setTinLocationFrontRight: (value: string)=> void
  tinLocationRearLeft: string
  setTinLocationRearLeft: (value: string)=> void
  tinLocationRearRight: string
  setTinLocationRearRight: (value: string)=> void
}
export interface VehicleInspectionPage2Props{
  onClose: () => void
  onClick: () => void
  isContinueButtonInactive: boolean
  inspectionData: InspectionData
  setInspectionData: (data: InspectionData) => void
}

export interface Services {
  id: number;
  name: string;
  value: number;
  description: string;
}

export interface Vehicles {
  id: number;
  engine: string;
}

export interface ServiceLocation {
  id: number;
  location: string;
}

export interface EditVehicleEngineProps {
  onClose: () => void
  onSubmit: () => void
  workOrderDetails?: WorkOrderProps
  setWorkOrderDetails: (data: WorkOrderProps) => void
  vehicleId: number
  setVehicleId: (value: number)=> void
  vehicles: Vehicles[]
}

export interface EditLineItemProps {
  onClose: () => void
  onSubmit: () => void
  workOrderDetails?: WorkOrderProps
  setWorkOrderDetails: (data: WorkOrderProps) => void
  serviceItem: ServiceItem[]
  setServiceItem: (data: ServiceItem[]) => void
  services: Services[]
  poNumber?: string
  setPoNumber: (value: string)=> void
}

export interface EditNotesProps {
  onClose: () => void
  onSubmit: () => void
  workOrderDetails?: WorkOrderProps
  setWorkOrderDetails: (data: WorkOrderProps) => void
  notes?: string
  setNotes: (value: string)=> void
}

export interface EditPoNumberProps {
  onClose: () => void
  onSubmit: () => void
  workOrderDetails?: WorkOrderProps
  setWorkOrderDetails: (data: WorkOrderProps) => void
  poNumber?: string
  setPoNumber: (value: string)=> void
}

export interface InspectionImagesProps{
  workOrderDetails?: WorkOrderProps
  inspectionImages: InspectionImages
  setinspectionImages: (data: InspectionImages) => void
  brakeFluid: boolean
  setBrakeFluid: (value: boolean)=> void
  windshieldFluid: boolean
  setWindshieldFluid: (value: boolean)=> void
  leakInspection: boolean
  setLeakInspection: (value: boolean)=> void
  oilCapLocation?: string
  setOilCapLocation: (value: string)=> void
  dipstickLocation?: string
  setDipstickLocation: (value: string)=> void
  oilFilterLocation: string
  setOilFilterLocation: (value: string)=> void
  oilFilterId: string
  setOilFilterId: (value: string)=> void
  servicesLocation?: ServiceLocation[]
  setServicesLocation?: React.Dispatch<React.SetStateAction<ServiceLocation[]>>;
  onClose: () => void
  onClick: () => void
  isContinueButtonInactive: boolean
}

export interface AdditionalVehicleInfoProps {
  vehicleVin?: string
  vin?: string
  setVin: (value: string) => void
  mileage?: number
  setMileage: (value: number) => void
  mileageLocation?: string
  setMileageLocation: (value: string)=> void
  vinLocation?: string
  setVinLocation: (value: string)=> void
  vehicleLocationFrontLeft?: string
  setVehicleLocationFrontLeft: (value: string)=> void
  vehicleLocationFrontRight?: string
  setVehicleLocationFrontRight: (value: string)=> void
  vehicleLocationRearLeft?: string
  setVehicleLocationRearLeft: (value: string)=> void
  vehicleLocationRearRight?: string
  setVehicleLocationRearRight: (value: string)=> void
  cabinFilterId?: string
  setCabinFilterId: (value: string)=> void
  engineFilterId?: string
  setEngineFilterId: (value: string)=> void
  onClose: () => void
  onClick: () => void
  isContinueButtonInactive: boolean
}

export interface CompleteWorkOrderModalProps {
  workOrderDetails?: WorkOrderProps,
  onClose: () => void
  onSubmit: (
    discountType: string,
    discountAmount: number,
    subscriptionTypeId?: number,
  ) => void
}
export interface CompleteInspectionModalProps{
  workOrderDetails?: WorkOrderProps,
  onClose: () => void
  onSubmit: (
    vin:string | undefined,
    mileage:number | undefined,
    inspectionData?: InspectionData,
    mileageLocation?: string,
    vinLocation?: string,
    vehicleLocation?: string,
    oilCapLocation?: string,
    dipstickLocation?: string,
    oilFilterLocation?: string,
    oilFilterId?: string,
    checkedFrontLeft?: boolean,
    checkedFrontRight?: boolean,
    checkedRearLeft?: boolean,
    checkedRearRight?: boolean,
    brakeFluid?: boolean,
    windshieldFluid?: boolean,
    leakInspection?: boolean,
    vehicleLocationFrontLeft?: string,
    vehicleLocationFrontRight?: string,
    vehicleLocationRearLeft?: string,
    vehicleLocationRearRight?: string,
    tinLocationFrontLeft?: string,
    tinLocationFrontRight?: string,
    tinLocationRearLeft?: string,
    tinLocationRearRight?: string,
    servicesLocation?: ServiceLocation[],
    cabinFilterId?: string,
    engineFilterId?: string,
    discountType?: string,
    discountAmount?: number,
    subscriptionTypeId?: number,
  ) => void
}

export interface EditVehicleEngineModalProps{
  workOrderDetails?: WorkOrderProps,
  setWorkOrderDetails: (data: WorkOrderProps) => void
  onClose: () => void
  onSubmit: (
    vehicleId: number,
  ) => void
}

export interface EditLineItemsModalProps{
  workOrderDetails?: WorkOrderProps,
  setWorkOrderDetails: (data: WorkOrderProps) => void
  onClose: () => void
  onSubmit: (
    serviceItem: ServiceItem[],
    poNumber?: string,
  ) => void
}
export interface EditPoNumberModalProps{
  workOrderDetails?: WorkOrderProps,
  setWorkOrderDetails: (data: WorkOrderProps) => void
  onClose: () => void
  onSubmit: (
    poNumber: string,
  ) => void
}
export interface EditNotesModalProps{
  workOrderDetails?: WorkOrderProps,
  setWorkOrderDetails: (data: WorkOrderProps) => void
  onClose: () => void
  onSubmit: (
    notes: string,
  ) => void
}

export interface CancelWorkOrderModalProps{
  workOrderDetails?: WorkOrderProps,
  setWorkOrderDetails: (data: WorkOrderProps) => void
  onClose: () => void
  onSubmit: (
    cancelReason: string,
    proofOfCancelLocation: string,
    notes: string,
  ) => void
}

export interface SubscriptionProps {
  onClose: () => void
  onClick: () => void
  setSelectedSubscription: (subscription?: SubscriptionType) => void
  selectedSubscription?: SubscriptionType
  vehicleSubscription?: VehicleSubscription
}

export interface InvoiceDiscountProps {
  totalOriginal: number,
  workOrderDetails?: WorkOrderProps,
  setInvoiceDiscountDetails: (
    discountType: string, discountAmount: number
  ) => void
  onBack: () => void
  onClick: () => void
  selectedSubscription?: SubscriptionType
}

export const completeWorkOrderModalsList = [
  'subscription',
  'invoice',
] as const;

export const assignWorkOrderModalsList = [
  'assign',
] as const;

export const editVehicleEngineModalsList = [
  'vehicle',
] as const;

export const editLineItemsModalsList = [
  'lineItems',
] as const;

export const editPoNumberModalsList = [
  'poNumber',
] as const;

export const editNotesModalsList = [
  'notes',
] as const;

export const completeInspectionModalsList = [
  'inspection',
  'inspection2',
  'inspectionImages',
  'additionalInfo',
  'subscription',
  'invoice',
] as const;

export type CompleteWorkOrderModals = typeof completeWorkOrderModalsList[
  number
];
export type CompleteInspectionModals = typeof completeInspectionModalsList[
  number
];
export type AssignWorkOrderModals = typeof assignWorkOrderModalsList[
  number
];
export type EditVehicleEngineModals = typeof editVehicleEngineModalsList[
  number
];
export type EditLineItemsModals = typeof editLineItemsModalsList[
  number
];
export type EditPoNumberModals = typeof editPoNumberModalsList[
  number
];
export type EditNotesModals = typeof editNotesModalsList[
  number
];

export interface ProductCardProps {
  title: string;
  price: number;
  isEstimate: boolean,
  description?: string;
  details?: string;
  onSelect: () => void;
  onRemove: () => void;
  selected: boolean;
  disabled?: boolean;
  discountedPrice?: number;
}
