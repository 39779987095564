import { GroupedWorkOrders } from '../interfaces/WorkOrder';
import styles from '../sass/components/WorkOrderList.module.scss';
import WorkOrderRow from './WorkOrderRow';

interface WorkOrderListProps {
  workOrders: GroupedWorkOrders
}

export default function WorkOrderList({ workOrders }: WorkOrderListProps) {
  return (
    <>
      {Object.keys(workOrders).map((scheduledDate) => (
        <div key={scheduledDate}>
          <div className={styles.date}>{scheduledDate}</div>
          {workOrders[scheduledDate].map((wo, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${scheduledDate}-${wo.id}-${index}`}>
              <WorkOrderRow workOrder={wo} />
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
