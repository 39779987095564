import ChevronLeft from '@mui/icons-material/ChevronLeft';
import cx from 'classnames';
import { FormEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import Alert from '../components/Alert';
import Button from '../components/Button';
import Field from '../components/Field';
import { Column, Container, Row } from '../components/Grid';
import { PasswordInput } from '../components/PasswordInput';
import TextInput from '../components/TextInput';
import { login } from '../features/auth/authSlice';
import styles from '../sass/components/CardPage.module.scss';
import { api } from '../util/api';
import {
  ROUTE_ACCOUNT_ROOT,
  ROUTE_AUTH_FORGOT_PASSWORD,
  ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
  ROUTE_ROOT,
} from '../util/constants';

export const authenticate = (
  credentials: {
    email: string,
    password: string,
  },
  onSuccess: (
    token: string,
    roles: string[],
    name: string,
    id: number,
    franchiseId: number,
    franchiseIds: number[],
    vendorId: number,
  ) => void,
  onIncorrectCredentials: (message: string) => void,
  onError: (message: string) => void,
) => {
  api.post(
    'auth/login',
    {
      email: credentials.email,
      password: credentials.password,
    },
  ).then((response) => {
    if (response.data) {
      const {
        // eslint-disable-next-line max-len
        authentication_token: token, roles, franchiseId, franchiseIds, name, id, vendorId,
      } = response.data;
      if (token) {
        onSuccess(token, roles, name, id, franchiseId, franchiseIds, vendorId);
      }
    }
  }).catch((ex) => {
    const { statusCode, message } = ex.response.data;
    if (statusCode === 400) {
      onIncorrectCredentials(message);
    } else {
      onError('Whoops, looks like something went wrong!');
    }
  });
};

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleEmailChange = (event: FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const handlePasswordChange = (event: FormEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const backToBooking = () => {
    navigate(ROUTE_ROOT);
  };

  const setUser = async (
    token: string,
    roles: string[],
    name: string,
    id: number,
    franchiseId?: number,
    franchiseIds?: number[],
    vendorId?: number,
  ) => {
    dispatch(login({
      email, token, roles, franchiseId, franchiseIds, name, id, vendorId,
    }));
    if (roles.includes('manager') || roles.includes('technician')) {
      navigate(
        ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
      );
    } else navigate(ROUTE_ACCOUNT_ROOT);
  };
  const handleLogin = () => {
    setError('');

    const onIncorrectCredentials = (message: string) => {
      setError(message);
    };

    const onError = () => {
      setError('Whoops, something went wrong!');
    };

    authenticate(
      { email, password },
      setUser,
      onIncorrectCredentials,
      onError,
    );
  };

  const hasEmailAndPassword = email !== '' && password !== '';

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    handleLogin();
  };

  return (
    <div className={styles.body}>
      <div className={styles.header}>
        <Button
          variant="tertiary"
          onClick={backToBooking}
        >
          <ChevronLeft />
          Back to Booking
        </Button>
      </div>
      <div className={styles.headerWrapper}>
        <h1 className={styles.desktopHeader}>Log In</h1>
        <h3 className={styles.mobileHeader}>Log In</h3>
        <h5 className={styles.desktopHeader}>
          Log in to your Go Oil Account to manage your bookings
        </h5>
        <p className={styles.mobileHeader}>
          Log in to your Go Oil Account to manage your bookings
        </p>
      </div>
      <div>
        <Container fluid>
          <Row>
            <Column md={6} mdOffset={3}>
              <div className={styles.card}>
                <Container fluid>
                  <Row>
                    <Column md={12} xs={12} lg={6}>
                      <div className={cx(
                        styles.cardColumn,
                        styles.firstCardColumn,
                      )}
                      >
                        <form
                          onSubmit={handleSubmit}
                        >
                          <Field label="Email" id="email">
                            <TextInput
                              value={email}
                              onChange={handleEmailChange}
                              placeholder="Email"
                              autocomplete="on"
                            />
                          </Field>
                          <Field label="Password" id="password">
                            <PasswordInput
                              value={password}
                              placeholder="Password"
                              onChange={handlePasswordChange}
                              autocomplete="on"
                            />
                          </Field>
                          <Alert message={error} type="error" />
                          <Link
                            className={styles.link}
                            to={ROUTE_AUTH_FORGOT_PASSWORD}
                          >
                            Forgot Password?
                          </Link>
                          <Button
                            variant="primary"
                            inactive={!hasEmailAndPassword}
                            type="submit"
                          >
                            Log In
                          </Button>
                        </form>
                      </div>
                    </Column>
                    <Column md={12} xs={12} lg={6}>
                      <div className={cx(
                        styles.centered,
                        styles.cardColumn,
                        styles.lastCardColumn,
                      )}
                      >
                        <h4>Don&apos;t Have an Account?</h4>
                        <p>
                          Don&apos;t have an account?
                          Create one by
                          booking an appointment
                          with Go Oil
                        </p>
                        <Button
                          onClick={backToBooking}
                          className={styles.fullWidthBottomButton}
                        >
                          Book
                        </Button>
                      </div>
                    </Column>
                  </Row>
                </Container>
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </div>
  );
}
