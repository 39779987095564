import cx from 'classnames';
import { add, format, parseISO } from 'date-fns';
import { useEffect, useRef } from 'react';
import { Appointment } from '../features/serviceLocation/serviceLocationSlice';
import styles from '../sass/components/TimeList.module.scss';

interface TimeListProps {
  times?: Appointment[];
  onSelectAppointment: (appt: Appointment) => void;
  selectedTime?: Appointment | null;
}

export default function TimeList({
  times,
  onSelectAppointment,
  selectedTime,
}: TimeListProps) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    wrapperRef.current?.scrollTo({ top: 0 });
  }, [times]);

  const selectedDay = (times && times.length)
    ? new Date(times[0].date)
    : new Date();

  const handleKeyDown = (appt: Appointment) => {
    onSelectAppointment(appt);
  };

  const isSelected = (time: Appointment) => selectedTime?.date === time.date
    && selectedTime.date === time.date
    && selectedTime.routeId === time.routeId;

  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      <div key={selectedDay.toISOString()} className={styles.daySection}>
        <div className={styles.daySectionHeader}>
          <span className="label">
            {format(selectedDay, 'EEEE, MMMM do, yyyy')}
          </span>
        </div>
        <div className={styles.appointmentTimes}>
          {times?.map((time) => (
            <div
              key={`${selectedDay}-${time.routeId}-${time.date}`}
              className={cx(styles.time, {
                [styles.selected]: isSelected(time),
                [styles.booked]: time.isBooked,
              })}
              tabIndex={0}
              role="option"
              aria-selected={isSelected(time)}
              onClick={() => onSelectAppointment(time)}
              onKeyDown={() => handleKeyDown(time)}
            >
              <span>
                {format(parseISO(time.date), 'hh:mmaaa')}
                {' '}
                -
                {' '}
                {format(add(parseISO(time.date), { hours: 1 }), 'hh:mmaaa')}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
