import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
import styles from '../sass/components/ServiceSelector.module.scss';
import AddOnList from './AddOnList';
import RegularServiceList from './RegularServiceList';
import Tab from './Tab';
import TabContainer from './TabContainer';
import { GET_USER_BY_ID } from '../util/gql';

interface ServiceSelectorProps {
  type: 'primary' | 'secondary';
}

const primaryService = (
  <div>
    <span>Select One Service</span>
    <p>
      Battery replacement and tire change
      can be selected as add-ons in the next step
    </p>
  </div>
);
const serviceSelection = (
  <div>
    <span>Select Requested Services</span>
  </div>
);

export default function ServiceSelector({ type }: ServiceSelectorProps) {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const { id: customerId } = useAppSelector((state) => state.customer);

  const [loadUserInformation,
    { data: userInfo, loading, error }] = useLazyQuery(GET_USER_BY_ID);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 1) {
      const userId = customerId || currentUser.id;
      loadUserInformation({ variables: { userId } });
    }
  }, [customerId, currentUser, loadUserInformation]);

  if (loading) {
    return (
      <div className={styles.services}>
        <div>Loading user information...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.services}>
        <div>Failed to load user information. Please try again.</div>
      </div>
    );
  }

  const vendorId = userInfo?.getUserById?.vendorId;
  if (vendorId !== 1) {
    return (
      <div className={styles.services}>
        <TabContainer>
          <Tab
            className="container__col-xs-12"
            tab
            selected={false}
            presentational
          >
            {type === 'primary' ? primaryService : 'Select Optional Add-Ons'}
          </Tab>
        </TabContainer>
        <div className={styles.scrollable}>
          {type === 'primary' ? <RegularServiceList /> : <AddOnList />}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.services}>
      <TabContainer>
        <Tab
          className="container__col-xs-12"
          tab
          selected={false}
          presentational
        >
          {serviceSelection}
        </Tab>
      </TabContainer>
      <div className={styles.scrollable}>
        <RegularServiceList />
      </div>
    </div>
  );
}
